<template>
    <Content>
      <InvitationLinkIsOwnPage
        @redirect-invite-supporters="redirectSupporters"
        @redirect-support="redirectSupport"
      />
      <Chat
        v-if="isOpenChatModal"
        @close-chat-modal="closeChatModal"
      />
    </Content>
</template>

<script>
import Chat from '@components/Сhat/Chat.vue';
import InvitationLinkIsOwnPage from '@innerworks_ds/innerworks-storybook-main/src/stories/InvitationLinkIsOwnPage/InvitationLinkIsOwnPage.vue';

export default {
  name: 'InvitationLinkIsOwn',
  components: {
    Chat,
    InvitationLinkIsOwnPage,
  },
  data: () => ({
    isOpenChatModal: false,
  }),
  mounted() {
    this.$store.dispatch('showComponent/showHeaderMain');
  },
  methods: {
    redirectSupporters() {
      this.$router.push({ path: '/share-result/info?opensharelink=true' });
    },
    redirectSupport() {
      this.isOpenChatModal = true;
    },
    closeChatModal() {
      this.isOpenChatModal = false;
    },
  },
};
</script>

<style scoped>

</style>
